import React from 'react';
import cx from "classnames";
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';
import { PremiumChat } from '@/icons';
import { useTranslation } from '@separate/hooks/translation';
import styles from './member.module.scss';

export default function ChatCard({ className, membershipLevel }) {
  const { t } = useTranslation();
  const linkRoute = membershipLevel === 'SEP+' ? '/qa' : '/premium';

  return (
    <Col md={12} sm={12} className={cx(className, 'mt-4')}>
      <Link href={linkRoute}>
        <a className={styles.cardLink}>
          <Card className={styles.chatCard}>
            <Card.Body className={cx("d-flex align-items-center", styles.cardBody)}>
              <span><PremiumChat /></span>
              <p className="mb-0 ms-2">
                {t("home.membership_dashboard.start_chat_description")}
              </p>
            </Card.Body>
            <Card.Footer className={cx(styles.footer, 'text-center')}>
              {membershipLevel === 'SEP+' ? t("home.membership_dashboard.start_chat_now") : t("home.index.unlock_with_premium")}
            </Card.Footer>
          </Card>
        </a>
      </Link>
    </Col>
  );
}

ChatCard.propTypes = {
  className: PropTypes.string,
  membershipLevel: PropTypes.string,
};
