import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.scss';
import Card from 'react-bootstrap/Card';

export default function CardItem(props) {
  const { className, cardWidth, children, ...extraProps } = props;

  return (
    <Card style={{ width: cardWidth }} className={cx(styles.card, className)} {...extraProps}>
      <Card.Body className={styles.cardBody}>
        {children
          ? children
          : <Card.Text className={styles.placeholder}>Add Content</Card.Text>
        }
      </Card.Body>
    </Card>
  );
}

CardItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cardWidth: PropTypes.string
};

CardItem.defaultProps = {
  cardWidth: "280px",
};
