import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import ArticleCard from '@separate/v4/components/ArticleCard';
import { useV4Translation } from '@v4/src/hooks/translation';

export default function Articles(props) {
  const { article } = props;
  const [ t ] = useV4Translation();
  return (
    <>
      <ArticleCard className={styles.article} article={article} cta={t("v4.home.articles.cta")}/>
    </>
  );
}

Articles.propTypes = {
  article: PropTypes.object,
};
