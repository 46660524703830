import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'react-bootstrap';
import AnalyticsLink from '@/components/AnalyticsLink';
import QuizSpotlightCard from '@/components/cards/QuizSpotlighCard';
import QuizSpotlightCardDesktop from '@/components/cards/QuizSpotlightCardDesktop';
import SurveyList from '@/components/surveys/SurveyList';
import { PANELIST_PRODUCT_ID, REWARDS_OPPORTUNITY_VIEWED } from '@separate/constants/analytics';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { logEvent } from '@separate/utilities/analytics';
import styles from './rewardsWrapper.module.scss';

export default function RewardsWrapper(props) {
  const { courseLoading, surveyListIsLoading, surveyData, courses, isOnDesktop } = props;
  const { t } = useTranslation();
  const locale = useLocale();

  const quizSpotlightComponent = () => {
    return isOnDesktop ? (
      <QuizSpotlightCardDesktop course={courses[0]} locale={locale} />
    ) : (
      <QuizSpotlightCard courses={courses} locale={locale} />
    );
  };

  const handleClick = (type) => {
    logEvent(REWARDS_OPPORTUNITY_VIEWED, {
      type,
      product_id: PANELIST_PRODUCT_ID,
      service_utilization: "true",
    });
  };

  return (
    <div className={cx(styles.wrapper, { [styles.reverseOrder]: locale === 'es' })}>
      <Row className={cx("mb-4 justify-content-center", styles.rowWrapper, { [styles.rowWrapperDesktop]: isOnDesktop })}>
        <Col sm={12}>
          <div className='d-flex align-items-center justify-content-between'>
            <h2 className={styles.sectionHeader}>{t("home.index.surveys_header_title")}</h2>
            <AnalyticsLink
              href="/earn/surveys"
              className={cx(styles.viewMore, "link")}
              onClick={() => handleClick("view_more_surveys")}
            >
              {t("home.index.view_more")}
            </AnalyticsLink>
          </div>
          <p className={cx(styles.headerSubtitle, "mt-2 mb-3")}>{t("home.index.surveys_header_subtitle")}</p>
          {surveyListIsLoading ? (
            <Spinner animation='border' size="lg" className={styles.spinner} />
          ) : (
            <SurveyList list={surveyData} locale={locale} isOnDesktop={isOnDesktop} />
          )}
        </Col>
      </Row>

      <Row className={cx("mb-4 justify-content-center", styles.rowWrapper, { [styles.rowWrapperDesktop]: isOnDesktop })}>
        <Col sm={12} className="mb-3">
          <div className='d-flex align-items-center justify-content-between'>
            <h2 className={styles.sectionHeader}>{t("home.index.learn_earn_title")}</h2>
            <AnalyticsLink
              href="/earn/learn"
              className={cx(styles.viewMore, "link")}
              onClick={() => handleClick("view_more_learn")}
            >
              {t("home.index.view_more")}
            </AnalyticsLink>
          </div>
          <p className={cx(styles.headerSubtitle, "mt-2 mb-3")}>{t("home.index.learn_earn_spotlight_subtitle")}</p>
          {courseLoading ? (
            <Spinner animation='border' size="lg" className={styles.spinner} />
          ) : (
            quizSpotlightComponent()
          )}
        </Col>
      </Row>
    </div>
  );
}

RewardsWrapper.propTypes = {
  className: PropTypes.string,
  courseLoading: PropTypes.bool,
  surveyListIsLoading: PropTypes.bool,
  surveyData: PropTypes.array,
  courses: PropTypes.array,
  isOnDesktop: PropTypes.bool,
};
