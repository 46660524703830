import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.scss';
import CardCarousel from '@separate/v4/components/CardCarousel';
import CardItem from '@separate/v4/components/CardCarousel/CardItem';
import Button from 'react-bootstrap/Button';
import { HomeArrowRight, HomeAccount, HomeAdvisor, HomeMedical, HomeRewards } from '@v4/src/icons';
import { useV4Translation } from '@v4/src/hooks/translation';
import { IsInViewProvider } from '@separate/v4/hooks/viewportListener';
import Link from 'next/link';
import { isFeatureEnabled } from '@separate/utilities/features';

export default function Services(props) {
  const { className } = props;
  const { t } = useV4Translation();
  const hidePodercard = isFeatureEnabled('hide_podercard_references');

  const items = [
    {
      icon: HomeMedical,
      href: "/premium",
      active: true,
      title: t("v4.home.services.items.0.title"),
      content: t("v4.home.services.items.0.content"),
    },
    {
      icon: HomeRewards,
      href: "/earn",
      title: t("v4.home.services.items.1.title"),
      content: t("v4.home.services.items.1.content"),
    },
    {
      icon: HomeAccount,
      href: "/podercard",
      title: t("v4.home.services.items.2.title"),
      content: t("v4.home.services.items.2.content")
    },
    {
      icon: HomeAdvisor,
      href: "/qa",
      title: t("v4.home.services.items.3.title"),
      content: t("v4.home.services.items.3.content")
    },
  ];

  const renderIcon = (icon) => {
    const IconComponent = icon;
    return <IconComponent className={styles.icon}/>;
  };

  return (
    <>
     <CardCarousel className={cx(styles.cardCarousel, className)}>
        {items.map((item, index) => (
        <Link href={item.href} passHref legacyBehavior key={index} >
          <a className={cx({"d-none": hidePodercard && item.href === "/podercard"})}>
            <IsInViewProvider horizontal>
              <div className={cx({ [styles.active]: item.active })} >
                <CardItem
                  className={cx(styles.cardItem)}
                >
                  <div className="d-flex align-items-start justify-content-between mb-3">
                    {renderIcon(item.icon)}
                    <span>0{(hidePodercard && index === items.length - 1) ? index : (index + 1)  }</span>
                  </div>
                  <h3 className="mb-2">{item.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: item.content}} />
                  <Button className={styles.iconButton}><HomeArrowRight /></Button>
                </CardItem>
              </div>
            </IsInViewProvider>
            {(!hidePodercard && index === 0) &&
              <div className={styles.popular}>
                <p className='m-0'>{t("v4.home.services.popular_products")}</p>
              </div>
            }
          </a>
          </Link>
        ))}
      </CardCarousel>
    </>
  );
}

Services.propTypes = {
  className: PropTypes.string,
};
