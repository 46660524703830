import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.scss';
import CardItem from './CardItem';

export default function CardCarousel(props) {
  const { className, children, ...extraProps } = props;

  return (
    <div className={cx(styles.cardCarousel, className)}>
      {children
        ? children
        : <CardItem />
      }
    </div>
  );
}

CardCarousel.propTypes = {
  className: PropTypes.string,
};
