import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import ListGroup from 'react-bootstrap/ListGroup';
import { useV4Translation } from '@v4/src/hooks/translation';
import { isFeatureEnabled } from '@separate/utilities/features';

export default function Disclaimer() {
  const { t } = useV4Translation();
  const items = t("v4.home.disclaimer.items", { returnObjects: true });
  const hidePodercard = isFeatureEnabled('hide_podercard_references');
  const [currentItems, setCurrentItems] = React.useState([]);

  React.useEffect(() => {
    if(hidePodercard) {
      setCurrentItems(items.slice(0, -3));
    } else {
      setCurrentItems(items);
    }
  }, [hidePodercard]);

  return (
    <div className={styles.disclaimer}>
      <div className="text-center">
        <h5>{t("v4.home.disclaimer.title")}</h5>
      </div>
      <ListGroup as="ol" numbered className={styles.list}>
        {currentItems.map((item, index) => (
          <ListGroup.Item as="li" key={index} dangerouslySetInnerHTML={{ __html: item }} className={styles.item} />
        ))}
      </ListGroup>
    </div>
  );
}

Disclaimer.propTypes = {
  className: PropTypes.string,
};
