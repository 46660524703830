import baseAuthAdminQuery from '@separate/api/sep/baseAuthAdminQuery';

export async function getCourses() {
  const response = await baseAuthAdminQuery({
    path: '/courses',
    method: 'get',
  });

  return response?.data;
}
