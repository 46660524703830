import React from 'react';
import { useV4Translation } from '@v4/src/hooks/translation';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { IsInViewProvider } from '@separate/v4/hooks/viewportListener';
import RenderLoginModal from '../RenderLoginModal';
import Articles from './Articles';
import Disclaimer from './Disclaimer';
import Faq from './Faq';
import styles from './index.module.scss';
import Services from './Services';
import { isFeatureEnabled } from '@separate/utilities/features';

export default function HomePage({ articles }) {
  const { t } = useV4Translation();
  const [activeText, setActiveText] = React.useState(0);
  const [showLogin, setShowLogin] = React.useState(false);
  const text = t("v4.home.main.header.text", { returnObjects: true });
  const hidePodercard = isFeatureEnabled('hide_podercard_references');

  React.useEffect(() => {
    if(activeText === text.length) {
      setActiveText(0);
    }
    const timer = setInterval(() => {
      setActiveText(activeText + 1);
    }, 1700);
    return () => clearTimeout(timer);
  }, [activeText]);


  return (
    <div className={styles.home}>
      <RenderLoginModal show={showLogin} onHide={() => setShowLogin(false)} />
      <div className={cx(styles.main, 'py-0 py-lg-5')}>
        <Container>
          <Row className="py-5 align-items-center">
            <Col lg={6} md={12} sm={12}>
              <div className={styles.content}>
                <h2 className={cx(styles.heading, 'mb-0')}>
                  {t("v4.home.main.header.welcome")}<br />
                </h2>

                <div className={cx(styles.headingAnimated, 'mb-4')}>
                  {t("v4.home.main.header.text", { returnObjects: true }).map((item, index) => (
                    <h2 className={cx(styles.heading, { [styles.active]: index === activeText }, 'mb-0')} key={index}>{item}</h2>
                  ))}
                </div>

                <p className="mb-5">{t("v4.home.main.subheader")}</p>
                <Button className={cx(styles.button, 'mb-4')} onClick={() => setShowLogin(true)}>{t("v4.home.main.cta")}</Button>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className={cx(styles.imageContainer)}>
                <img src={require('@v4/images/home/main.png').default.src} alt="background" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={cx(styles.services, 'py-5')}>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h3 className={cx(styles.underlinedHeading, 'mb-5')} dangerouslySetInnerHTML={{__html: t("v4.home.services.header")}}></h3>
            </Col>
            <Col lg={12} className="d-flex align-items-center justify-content-center px-0">
              <Services />
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={cx(styles.health, 'py-0 py-lg-5')}>
        <Container>
          <Row className="py-5 align-items-center">
            <Col lg={12} className='text-center order-sm-0 mb-5 pb-md-5 '>
              <h3>{t("v4.home.benefits.header")}</h3>
            </Col>
            <Col lg={6} md={12} sm={12} className="order-2 order-md-1">
              <div className={styles.content}>
                <h2 className={cx(styles.heading, 'mb-4')}>{t("v4.home.benefits.health.title")}</h2>
                <p className="mb-5" dangerouslySetInnerHTML={{ __html: t("v4.home.benefits.health.content") }} />
                <Row className="mb-4">
                  {t("v4.home.benefits.health.items", { returnObjects: true }).map((item, index) => (
                    <Col xs={12} md={6} key={index}>
                      <span className={styles.bullet} dangerouslySetInnerHTML={{ __html: item }}></span>
                    </Col>
                  ))}
                </Row>
                <Link href="/premium">
                  <Button className={styles.button}>{t("v4.home.benefits.health.cta")}</Button>
                </Link>
              </div>
            </Col>
            <Col lg={6} md={12} className="order-1 order-md-2">
              <IsInViewProvider>
                <div className={cx(styles.imageContainer, styles.animated)}>
                  <img src={require('@v4/images/home/health.png').default.src} alt="background" />
                </div>
              </IsInViewProvider>
            </Col>

          </Row>
        </Container>
      </div>

      <div className={cx(styles.rewards, 'py-0 py-lg-5')}>
        <Container>
          <Row className="py-5 align-items-center">
            <Col lg={6} md={12} className="order-1">
              <IsInViewProvider>
                <div className={cx(styles.imageContainer, styles.animated)}>
                  <img src={require('@v4/images/home/rewards.png').default.src} alt="background" />
                </div>
              </IsInViewProvider>
            </Col>
            <Col lg={6} md={12} sm={12} className="order-2">
              <div className={styles.content}>
                <h2 className={cx(styles.heading, 'mb-4')}>{t("v4.home.benefits.rewards.title")}</h2>
                <p className="mb-5" dangerouslySetInnerHTML={{ __html : t("v4.home.benefits.rewards.content")}} />
                <Row className="mb-4">
                  {t("v4.home.benefits.rewards.items", { returnObjects: true }).map((item, index) => (
                    <Col xs={12} md={6} key={index}>
                      <span className={styles.bullet} dangerouslySetInnerHTML={{ __html: item }}></span>
                    </Col>
                  ))}
                </Row>
                <Link href="/earn">
                  <Button className={styles.button}>{t("v4.home.benefits.rewards.cta")}</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {!hidePodercard &&
        <div className={cx(styles.banking, 'py-0 py-lg-5')}>
        <Container>
          <Row className="py-5 align-items-center">
            <Col lg={6} md={12} sm={12} className="order-2 order-md-1">
              <div className={styles.content}>
                <h2 className={cx(styles.heading, 'mb-4')}>{t("v4.home.benefits.banking.title")}</h2>
                <p className="mb-5" dangerouslySetInnerHTML={{ __html: t("v4.home.benefits.banking.content")}} />
                <Row className="mb-4">
                  {t("v4.home.benefits.banking.items", { returnObjects: true }).map((item, index) => (
                    <Col xs={12} md={6} key={index}>
                      <span className={styles.bullet} dangerouslySetInnerHTML={{ __html: item }}></span>
                    </Col>
                  ))}
                </Row>
                <Link href="/podercard">
                  <Button className={styles.button}>{t("v4.home.benefits.banking.cta")}</Button>
                </Link>
              </div>
            </Col>
            <Col lg={6} md={12} className="order-1 order-md-2">
              <IsInViewProvider>
                <div className={cx(styles.imageContainer, styles.animated)}>
                  <img src={require('@v4/images/home/bank-account.png').default.src} alt="background" />
                </div>
              </IsInViewProvider>
            </Col>
          </Row>
        </Container>
        </div>
      }

      <div className={cx(styles.advisor, 'py-0 py-lg-5', {[styles.backgroundWhite]: hidePodercard})}>
        <Container>
          <Row className="py-5 align-items-center">
            <Col lg={6} md={12} className={cx('order-1', {'order-md-2': hidePodercard})}>
              <IsInViewProvider>
                <div className={cx(styles.imageContainer, styles.animated, {[styles.floatLeft]: !hidePodercard})}>
                  <img src={require('@v4/images/home/community.png').default.src} alt="background" />
                </div>
              </IsInViewProvider>
            </Col>
            <Col lg={6} md={12} sm={12} className={cx('order-2', {'order-md-1': hidePodercard})}>
              <div className={styles.content}>
                <h2 className={cx(styles.heading, 'mb-4')}>{t("v4.home.benefits.advisor.title")}</h2>
                <p className="mb-5" dangerouslySetInnerHTML={{ __html: t("v4.home.benefits.advisor.content") }} />
                <Row className="mb-4">
                  {t("v4.home.benefits.advisor.items", { returnObjects: true }).map((item, index) => (
                    <Col xs={12} md={6} key={index}>
                      <span className={styles.bullet} dangerouslySetInnerHTML={{ __html: item }}></span>
                    </Col>
                  ))}
                </Row>
                <Link href="/qa">
                  <Button className={styles.button}>{t("v4.home.benefits.advisor.cta")}</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={cx(styles.articles, 'py-5')}>
        <Container>
          <Col xs={12}>
            <h3 className={cx(styles.underlined, 'mb-3')}>{t("v4.home.articles.header")}</h3>
          </Col>
          <Row>
            {articles.map((item, index) => (
              <Col md={4} key={index} className="p-3">
                <Articles article={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className={cx(styles.faq, 'py-5')}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h3>{t("v4.home.faq.header")}</h3>
            </Col>
            <Col xs={12}>
              <Faq />
            </Col>
          </Row>
        </Container>
      </div>

      <div className={cx(styles.disclaimer, 'py-5')}>
        <Container>
          <Row>
            <Col xs={12}>
              <Disclaimer />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

}

HomePage.propTypes = {
  articles: PropTypes.array,
};
