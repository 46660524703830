import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export function IsInViewProvider({children, horizontal, center}) {
  const [isInViewport, setIsInViewport] = React.useState(false);
  const refs = React.useRef();

  function observeElement(element) {
    const options = () => {
      if(horizontal) {
        return {
          rootMargin: '0% -50% 0% -50%',
          threshold: 0,
        }
      }

      if(center) {
        return {
          rootMargin: '-50% 0% -50% 0%',
          threshold: 0,
        }
      } else {
        return {
          rootMargin: '0px',
          threshold: 0.3,
        }
      }
    }
    const ioConfiguration = {
      rootMargin: `${horizontal ? '0% -50% 0% -50%' : "0px"}`,
      threshold: horizontal ? 0 : 0.8,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsInViewport(true);
          return;
        }
        setIsInViewport(false);
      });
    }, options());

    observer.observe(element);
  }

  React.useEffect(() => {
    if (refs) {
      observeElement(refs.current);
    }
  }, [refs]);

  return (
    <>
      {React.cloneElement(children, { className: cx(children.props.className, { isInViewport }), ref: refs})}
    </>
  )
}

IsInViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
  horizontal: PropTypes.bool,
  center: PropTypes.bool
};

IsInViewProvider.defaultProps = {
  horizontal: false,
  center: false
};
