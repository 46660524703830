import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Accordion from '@separate/v4/components/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';
import { useV4Translation } from '@v4/src/hooks/translation';

export default function Faq() {
  const { t } = useV4Translation();
  const items = t("v4.home.faq.items", { returnObjects: true });

  return (
    <>
     <Accordion items={items} className={cx(styles.accordion, "mb-5")} />
     <Card className={cx(styles.card, 'mx-auto text-center')}>
      <Card.Body>
        <Card.Title className={styles.title}>{t("v4.home.faq.more.title")}</Card.Title>
        <Card.Text className={styles.text} dangerouslySetInnerHTML={{ __html: t("v4.home.faq.more.content")}} />
        <a href="tel:(726) 202-1213" className={styles.button}>{t("v4.home.faq.more.cta")}</a>
      </Card.Body>
    </Card>
    </>
  );
}

Faq.propTypes = {
  className: PropTypes.string,
};
