import { useQuery } from 'react-query';
import { getCourses } from '@/api/courses';
import { useCurrentUserSession } from '@separate/hooks/session';

const COURSES_CACHE_KEY = 'courses';

export function useGetCourses() {
  const { idToken } = useCurrentUserSession();

  const result = useQuery({
    queryKey: [COURSES_CACHE_KEY, idToken],
    queryFn: getCourses,
  });
  const { isLoading, data } = result;

  return { isLoading, data };
}
