import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import cx from 'classnames';
import styles from './index.module.scss';
import { ArrowRight } from '../../icons';
import ContentstackImage from '../ContentstackImage';
import { articleImage } from '@/utilities/articles/articleCard';
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import Link from 'next/link';
import Badge from 'react-bootstrap/Badge';

export default function ArticleCard(props) {
  const { className, article, cta } = props;
  const isLocked = article.is_premium;
  const linkToArticle = isLocked ? '#/' : `/articles/${article.article_category}${article.url}`;
  return (
    <Card className={cx(styles.card, className)}>
      <ContentstackImage
        src={articleImage(article)}
        width={230}
        height={130}
      />
      <Badge className={cx(styles.badge, 'text-uppercase')}>{article?.article_category}</Badge>
      <Card.Body>
        <span className={styles.date}>Published {formatLocalDateSimple(article?.publish_details?.time)}</span>
        <Card.Title className={styles.title}>{article.title}</Card.Title>
        <Card.Text className={styles.text}>
          {article.article_description}
        </Card.Text>
        <Link href={linkToArticle} scroll={false}>
          <Button className={cx(styles.link, 'px-0')}>{cta ? cta : 'Read'}<ArrowRight className="ms-2"/></Button>
        </Link>
      </Card.Body>
    </Card>
  );
}

ArticleCard.propTypes = {
  className: PropTypes.string,
  article: PropTypes.object,
  cta: PropTypes.string
};
