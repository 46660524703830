import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import AnalyticsLink from '@/components/AnalyticsLink';
import {
  CardLinearGradient,
  EducationLinearGradient,
  HealthLinearGradient,
  ChevronRight,
  Earn,
  OpenBook,
} from '@/icons';
import { localeType } from '@/types';
import { surveyTitle } from '@/utilities/survey';
import { useTranslation } from '@separate/hooks/translation';
import { asCurrency } from '@separate/utilities/string';
import styles from './quizSpotlight.module.scss';

export default function QuizSpotlightCard(props) {
  const { className, courses, locale } = props;
  const { t } = useTranslation();

  const categoryMapping = {
    1: {
      category: t('home.categories.finance'),
      icon: CardLinearGradient,
    },
    2: {
      category: t('home.categories.education'),
      icon: EducationLinearGradient,
    },
    4: {
      category: t('home.categories.health'),
      icon: HealthLinearGradient,
    },
  };

  const renderIcon = (categoryId) => {
    if (!categoryId) return null;

    const IconComponent = categoryMapping[categoryId]?.icon;
    return <IconComponent className={styles.icon} />;
  };

  if (!courses) return null;

  return (
    <div>
      {courses.map((course, index) => (
        <AnalyticsLink
          key={course.id}
          href={`/surveys/${course.id}`}
          className={styles.cardLink}
          position={`${index}`}
          activity='rewards_engagement_start'
          type='learn_earn'
          step={undefined}
        >
          <div
            className={cx(styles.card, className)}
          >
            <div className={cx(styles.top, 'd-flex')}>
              <img
                src={course.thumbnail_image_path || require('@images/course_scale.png').default.src}
                alt="header image"
              />

              <div className='mx-3 d-flex flex-column justify-content-between'>
                {course.survey_category_id && (
                  <div className={styles.topHeader}>
                    {renderIcon(course.survey_category_id)}
                    <span>{categoryMapping[course.survey_category_id].category}</span>
                  </div>
                )}
                <h6 className='mt-2'>{surveyTitle(course, locale)}</h6>
              </div>

              <ChevronRight className={styles.chevronRight} />
            </div>

            <div className={cx(styles.bottom, 'd-flex justify-content-between')}>
              <div className={cx(styles.column, "d-flex")}>
                <div className={styles.iconWrapper}>
                  <Earn className={styles.icon} />
                </div>
                <div className={cx(styles.columnContent, "d-flex flex-column")}>
                  <span>{t('home.survey_card.you_can_earn')}</span>
                  <p>{asCurrency(course.incentive)}</p>
                </div>
              </div>
              <div className={cx(styles.column, "d-flex")}>
                <div className={styles.iconWrapper}>
                  <OpenBook className={styles.icon} />
                </div>
                <div className={cx(styles.columnContent, "d-flex flex-column")}>
                  <span>{t('home.survey_card.time_taken')}</span>
                  <p>{course.interview_length || 0} min</p>
                </div>
              </div>
            </div>
          </div>
        </AnalyticsLink>
      ))}
    </div>
  );
}

QuizSpotlightCard.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.string,
  courses: PropTypes.array,
  locale: localeType,
};
